import React, { useContext, createContext, useReducer, useEffect } from "react";

import { fetchInline } from "~/utilities/fetchHelper";

import {
    salesforceApiState,
    salesforceApiReducer,
} from "~/reducers/salesforceApi";

import { useOptionsContext } from "~/context/OptionsContext";

const SalesforceApiContext = createContext();

async function fetchSalesforceApiData(
    salesforceApiPrefillUrl,
    lotId,
    dispatch,
    signal,
) {
    try {
        const response = await fetchInline(
            `${process.env.GATSBY_ADMIN_SITE_URL}${salesforceApiPrefillUrl}${lotId}`,
            { signal },
        );

        if (response?.code && response?.data) {
            switch (response.code) {
                case "Success":
                    const caseNumber = response.data.case_number;

                    dispatch({
                        type: "SET_CASE_NUMBER",
                        salesforceCaseNumber: caseNumber,
                    });
                    break;
                case "Error":
                    dispatch({
                        type: "SET_ERROR",
                        errorMessage: "Issues loading product information",
                        error: response.message,
                    });
                    break;
                default:
                    dispatch({
                        type: "SET_CASE_NUMBER",
                        salesforceCaseNumber: "",
                    });
            }
        }
    } catch (e) {
        if (e instanceof DOMException && e.name === "AbortError") {
            return;
        } else {
            console.log(e);
            dispatch({
                type: "SET_ERROR",
                errorMessage: "Issues loading product information",
                error: e,
            });
        }
    }
}

export const SalesforceApiProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        salesforceApiReducer,
        salesforceApiState,
    );
    const { salesforceLotId, salesforceCaseNumber, salesforceIsLoading } =
        state;

    const { salesforceApiPrefillUrl } = useOptionsContext();

    useEffect(() => {
        const abortController = new AbortController();

        if (salesforceApiPrefillUrl && salesforceLotId) {
            // attempt to get product data
            fetchSalesforceApiData(
                salesforceApiPrefillUrl,
                salesforceLotId,
                dispatch,
                abortController.signal,
            );
        }

        return () => abortController.abort();
    }, [salesforceApiPrefillUrl, salesforceLotId]);

    const setSalesforceLotId = (newLotId) => {
        if (newLotId !== salesforceLotId) {
            dispatch({ type: "SET_LOT_ID", salesforceLotId: newLotId });
        }
    };

    return (
        <SalesforceApiContext.Provider
            value={{
                salesforceLotId,
                salesforceCaseNumber,
                setSalesforceLotId,
                salesforceIsLoading,
            }}
        >
            {children}
        </SalesforceApiContext.Provider>
    );
};

export const useSalesforceApiContext = () => {
    const context = useContext(SalesforceApiContext);

    if (context === undefined) {
        throw new Error(
            "useSalesforceApiContext must be used within a SalesforceApiProvider",
        );
    }

    return context;
};
