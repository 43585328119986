import { css } from "@emotion/react";
import styled from "@emotion/styled";

const getMargin = ({ noGutter, noVerticalSpacing, theme }) => {
    return css`
        margin-left: ${noGutter ? `0` : `-${theme.layout.gutterNumber / 2}px`};
        margin-right: ${noGutter ? `0` : `-${theme.layout.gutterNumber / 2}px`};
        margin-top: ${noVerticalSpacing ? `0` : `-${theme.layout.gutter}`};
        margin-bottom: ${noVerticalSpacing ? `0` : `${theme.layout.gutter}`};

        @media (min-width: ${theme.breakpoints.largeDesktop}) {
            margin-left: ${noGutter
                ? `0`
                : `-${theme.layout.largeGutterNumber / 2}px`};
            margin-right: ${noGutter
                ? `0`
                : `-${theme.layout.largeGutterNumber / 2}px`};
            margin-top: ${noVerticalSpacing
                ? `0`
                : `-${theme.layout.largeGutter}`};
            margin-bottom: ${noVerticalSpacing
                ? `0`
                : `${theme.layout.largeGutter}`};
        }
    `;
};

const Row = styled.div`
    ${getMargin};
    flex: 0 1 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 100%;
    justify-content: ${({ justifyContent }) =>
        justifyContent ? justifyContent : "flex-start"};
    align-items: ${({ alignItems }) => (alignItems ? alignItems : "stretch")};

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export default Row;
