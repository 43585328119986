import { cloneDeep } from "lodash";

import { isBrowser } from "~/utilities/helpers";
import { upsertOption } from "~/utilities/reducer";

export const salesforceApiState = {
    salesforceLotId: null,
    salesforceCaseNumber: "",
    errorMessage: "",
    salesforceIsLoading: true,
};

export function salesforceApiReducer(state, action) {
    let updatedState = state;
    const currentState = cloneDeep(state);

    const { type, salesforceLotId, salesforceCaseNumber, errorMessage, error } =
        action;

    switch (type) {
        case "SET_LOT_ID":
            updatedState = upsertOption(currentState, {
                salesforceLotId,
            });
            break;
        case "SET_CASE_NUMBER":
            updatedState = upsertOption(currentState, {
                salesforceCaseNumber,
                salesforceIsLoading: false,
                errorMessage: "",
            });
            break;
        case "SET_ERROR":
            updatedState = upsertOption(currentState, {
                salesforceIsLoading: false,
                errorMessage,
            });

            if (isBrowser && error) {
                console.dir(error);
                console.log("Issues loading product information");
            }

            break;
        default:
            if (isBrowser) {
                console.log(`Unkown Salesforce API Action ${type}`);
            }
            break;
    }

    return updatedState;
}
