import { useStaticQuery, graphql, navigate } from "gatsby";
import React, {
    useContext,
    useCallback,
    createContext,
    useReducer,
    useEffect,
} from "react";
import { isEmpty, find, keys } from "lodash";

import { getSecondaryCategory } from "~/utilities/products";
import { getSingleton } from "~/utilities/localStorageItem";

const CompareContext = createContext();

const getStateWithNewProduct = (product, state, action, category) => {
    const savedCategory = state?.categoryProducts[category] ?? [];
    const isProductSelected = find(savedCategory, { id: action.id });

    if (isProductSelected) {
        return {
            ...state,
            clickedOnProductId: isProductSelected.id,
            categoryProducts: {
                ...state.categoryProducts,
                [category]: savedCategory,
            },
        };
    }

    const updatedProducts = [...savedCategory, product];

    if (updatedProducts.length > 4) {
        return state;
    }

    if (product) {
        return {
            ...state,
            categoryProducts: {
                ...state.categoryProducts,
                [category]: updatedProducts,
            },
        };
    }
};

const findProduct = (allProducts, id, englishCode) => {
    const product = find(allProducts, { id }) || {};

    if (isEmpty(product) || product.languageCode === englishCode) {
        return product;
    }

    const englishProduct = find(product.translations, {
        languageCode: englishCode,
    });

    if (isEmpty(englishProduct)) {
        return product;
    }

    return englishProduct;
};

const compareInSelectedProducts = (state, action, foundProduct) => {
    const activeCode = action.translationProps?.activeCode || "en";

    const productCategory = getSecondaryCategory(
        foundProduct.productCategories,
    );

    const productIsInSelectedCategory =
        state.selectedCategorySlug === productCategory.slug;

    if (!productIsInSelectedCategory) {
        return null;
    }

    return {
        ...getStateWithNewProduct(
            foundProduct,
            state,
            action,
            state.selectedCategorySlug,
        ),
        show: true,
        activeCode,
    };
};

const compareInProductCategory = (state, action, foundProduct) => {
    const activeCode = action.translationProps?.activeCode || "en";
    const productCategory = getSecondaryCategory(
        foundProduct.productCategories,
    );

    const category = state.categoryProducts[productCategory.slug] ?? [];
    const product = find(category, { id: foundProduct.id });

    if (isEmpty(product)) {
        return null;
    }

    return {
        ...state,
        selectedCategory: productCategory,
        selectedCategorySlug: productCategory.slug,
        clickedOnProductId: product.id,
        compareOrder: productCategory.categoryData.compareOrder,
        show: true,
        activeCode,
    };
};

const getDefaultCompare = (state, action, foundProduct) => {
    const activeCode = action.translationProps?.activeCode || "en";
    const productCategory = getSecondaryCategory(
        foundProduct.productCategories,
    );

    return {
        ...getStateWithNewProduct(
            foundProduct,
            state,
            action,
            productCategory.slug,
        ),
        selectedCategory: productCategory,
        selectedCategorySlug: productCategory.slug,
        compareOrder: productCategory.categoryData.compareOrder,
        show: true,
        activeCode,
    };
};

const reducer = (state, action) => {
    switch (action.type) {
        case "COMPARE_PRODUCT":
            const englishCode = action.translationProps?.englishCode || "en";
            const foundProduct = findProduct(
                state.allProducts,
                action.id,
                englishCode,
            );

            if (isEmpty(foundProduct)) {
                return state;
            }

            const inSelected = compareInSelectedProducts(
                state,
                action,
                foundProduct,
            );

            if (inSelected) {
                return inSelected;
            }

            const inProductCategory = compareInProductCategory(
                state,
                action,
                foundProduct,
            );

            if (inProductCategory) {
                return inProductCategory;
            }

            return getDefaultCompare(state, action, foundProduct);
        case "REMOVE_PRODUCT":
            const savedCategory =
                state.categoryProducts[state.selectedCategorySlug] ?? [];

            const productsLess = savedCategory.filter(
                ({ id }) => id !== action.id,
            );
            return {
                ...state,
                categoryProducts: {
                    ...state.categoryProducts,
                    [state.selectedCategorySlug]: productsLess,
                },
            };
        case "CLEAR_ALL":
            return {
                ...state,
                show: false,
                showClearAll: false,
                categoryProducts: {},
            };
        case "SET_SHOW":
            return {
                ...state,
                show: action.show,
                showClearAll: !action.show ? false : state.showClearAll,
            };
        case "SET_SHOW_CLEAR_ALL":
            return {
                ...state,
                showClearAll: action.showClearAll,
            };
        case "SET_SAVED_COMPARE_STATE":
            const { isMounted, savedCompareState } = action.handleCompareState;

            if (isMounted) {
                return {
                    ...savedCompareState,
                    allProducts: state.allProducts,
                };
            }
            return state;
        case "SET_PRODUCTS_ON_PAGE_CHANGE":
            const categoryProducts = state.categoryProducts;

            if (isEmpty(categoryProducts)) {
                return state;
            }

            const categoryKeys = keys(categoryProducts);
            const theSelectedCatSlug = find(categoryKeys, (category) =>
                action.pathname.includes(category),
            );

            if (
                theSelectedCatSlug &&
                state.categoryProducts[theSelectedCatSlug].length > 0
            ) {
                const theSelectCat = getSecondaryCategory(
                    state.categoryProducts[theSelectedCatSlug][0]
                        .productCategories,
                );

                return {
                    ...state,
                    selectedCategory: theSelectCat,
                    selectedCategorySlug: theSelectedCatSlug,
                };
            }

            return { ...state };
        case "REMOVE_CLICKED_ON_PRODUCT_ID":
            if (action.isMounted) {
                return {
                    ...state,
                    clickedOnProductId: null,
                };
            }
            return state;
        default:
            return state;
    }
};

const initialState = {
    allProducts: [],
    categoryProducts: {},
    selectedCategorySlug: "",
    selectedCategory: {},
    compareOrder: [],
    show: false,
    showClearAll: false,
    clickedOnProductId: null,
    activeCode: "en",
};

export const CompareProvider = ({ children }) => {
    const { allWpProduct } = useStaticQuery(graphql`
        query CompareProvider {
            allWpProduct(
                filter: { productInformation: { typeId: { eq: "grouped" } } }
            ) {
                nodes {
                    ...ProductCompareFragment
                }
            }
        }
    `);

    const [state, dispatch] = useReducer(reducer, initialState, () => {
        return {
            ...initialState,
            allProducts: allWpProduct.nodes ?? [],
        };
    });

    const localStorageItem = getSingleton("compareState");
    const selectedProducts =
        state.categoryProducts[state.selectedCategorySlug] ?? [];

    useEffect(() => {
        let isMounted = true;

        if (localStorageItem.has()) {
            dispatch({
                type: "SET_SAVED_COMPARE_STATE",
                handleCompareState: {
                    isMounted,
                    savedCompareState: localStorageItem.getCollection(),
                },
            });
        }

        return () => {
            isMounted = false;
        };
    }, [localStorageItem]);

    useEffect(() => {
        const savedCompareState = { ...state };
        delete savedCompareState.allProducts;
        localStorageItem.addCollection(savedCompareState);
    }, [state, localStorageItem]);

    useEffect(() => {
        let isMounted = true;
        if (state.clickedOnProductId !== null) {
            setTimeout(
                () =>
                    dispatch({
                        type: "REMOVE_CLICKED_ON_PRODUCT_ID",
                        isMounted,
                    }),
                2000,
            );
        }
        return () => {
            isMounted = false;
        };
    }, [state.clickedOnProductId]);

    const goToComparePage = () => {
        if (state.categoryProducts[state.selectedCategorySlug].length > 1) {
            navigate(`/compare?lang=${state.activeCode}`);
            return;
        }

        if (state.selectedCategory.uri) {
            navigate(`${state.selectedCategory.uri}?lang=${state.activeCode}`);
            return;
        }
    };

    const compareProduct = useCallback(
        (id, translationProps) => {
            dispatch({
                type: "COMPARE_PRODUCT",
                id,
                translationProps,
            });
        },
        [dispatch],
    );

    const setUrlPath = useCallback(
        (pathname) => {
            dispatch({
                type: "SET_PRODUCTS_ON_PAGE_CHANGE",
                pathname,
            });
        },
        [dispatch],
    );

    const setShowClearAll = (showClearAll) => {
        dispatch({ type: "SET_SHOW_CLEAR_ALL", showClearAll });
    };

    return (
        <CompareContext.Provider
            value={{
                selectedProducts,
                show: state.show,
                showClearAll: state.showClearAll,
                selectedCategory: state.selectedCategory,
                compareOrder: state.compareOrder,
                clickedOnProductId: state.clickedOnProductId,
                compareProduct,
                setShowClearAll,
                setUrlPath,
                goToComparePage,
                removeProduct: (id) => dispatch({ type: "REMOVE_PRODUCT", id }),
                clearAll: () => dispatch({ type: "CLEAR_ALL" }),
                setShow: (show) => dispatch({ type: "SET_SHOW", show }),
            }}
        >
            {children}
        </CompareContext.Provider>
    );
};

export const useCompareContext = () => {
    const context = useContext(CompareContext);

    if (context === undefined) {
        throw new Error(
            "useCompareContext must be used within a CompareProvider",
        );
    }

    return context;
};
