import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
} from "react";

const IntersectionContext = createContext();

const initialState = {
    itemsToWatch: [],
    navItems: [],
    activeItem: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "REGISTER":
            if (action.item.id && state.navItems.includes(action.item.id)) {
                const exists = state.itemsToWatch.find((el) =>
                    el.isEqualNode(action.item),
                );

                return {
                    ...state,
                    itemsToWatch: exists
                        ? state.itemsToWatch
                        : state.itemsToWatch.concat(action.item),
                };
            }

            return state;
        case "REGISTER_NAV_ITEM":
            return {
                ...state,
                navItems: action.ids,
            };
        case "SET_ACTIVE_ITEM":
            return {
                ...state,
                activeItem: action.item,
            };
        default:
            return state;
    }
};

export const IntersectionProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const registerItemToWatch = useCallback(
        (item) => dispatch({ type: "REGISTER", item }),
        [],
    );
    const registerNavItems = useCallback(
        (ids) => dispatch({ type: "REGISTER_NAV_ITEM", ids }),
        [],
    );
    const setActiveItem = useCallback(
        (item) => dispatch({ type: "SET_ACTIVE_ITEM", item }),
        [],
    );

    return (
        <IntersectionContext.Provider
            value={{
                itemsToWatch: state.itemsToWatch,
                registerItemToWatch,
                activeItem: state.activeItem,
                setActiveItem,
                registerNavItems,
            }}
        >
            {children}
        </IntersectionContext.Provider>
    );
};

export const useIntersectionContext = () => {
    const context = useContext(IntersectionContext);
    const ref = useRef();

    if (context === undefined) {
        throw new Error(
            "useIntersectionContext must be used within a OptionsProvider",
        );
    }

    const {
        registerItemToWatch,
        itemsToWatch,
        activeItem,
        setActiveItem,
        registerNavItems,
    } = context;

    useEffect(() => {
        if (ref.current) {
            registerItemToWatch(ref.current);
        }
    }, [registerItemToWatch]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (
                        entry.target.id !== activeItem &&
                        entry.isIntersecting
                    ) {
                        setActiveItem(entry.target.id);
                    }
                });
            },
            {
                threshold: 0,
                rootMargin: "-50% 0px -50% 0px",
            },
        );

        itemsToWatch.forEach((element) => observer.observe(element));

        return () => {
            observer.disconnect();
        };
    }, [itemsToWatch, setActiveItem, activeItem]);

    return { ref, activeItem, registerNavItems };
};
