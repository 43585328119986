exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-compare-jsx": () => import("./../../../src/pages/compare.jsx" /* webpackChunkName: "component---src-pages-compare-jsx" */),
  "component---src-pages-savings-calculator-jsx": () => import("./../../../src/pages/SavingsCalculator.jsx" /* webpackChunkName: "component---src-pages-savings-calculator-jsx" */),
  "component---src-templates-accessory-category-jsx": () => import("./../../../src/templates/AccessoryCategory.jsx" /* webpackChunkName: "component---src-templates-accessory-category-jsx" */),
  "component---src-templates-accessory-jsx": () => import("./../../../src/templates/Accessory.jsx" /* webpackChunkName: "component---src-templates-accessory-jsx" */),
  "component---src-templates-application-jsx": () => import("./../../../src/templates/Application.jsx" /* webpackChunkName: "component---src-templates-application-jsx" */),
  "component---src-templates-case-study-jsx": () => import("./../../../src/templates/CaseStudy.jsx" /* webpackChunkName: "component---src-templates-case-study-jsx" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/Event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-insight-jsx": () => import("./../../../src/templates/Insight.jsx" /* webpackChunkName: "component---src-templates-insight-jsx" */),
  "component---src-templates-insight-tag-jsx": () => import("./../../../src/templates/InsightTag.jsx" /* webpackChunkName: "component---src-templates-insight-tag-jsx" */),
  "component---src-templates-insight-topic-jsx": () => import("./../../../src/templates/InsightTopic.jsx" /* webpackChunkName: "component---src-templates-insight-topic-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/News.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-product-category-jsx": () => import("./../../../src/templates/ProductCategory.jsx" /* webpackChunkName: "component---src-templates-product-category-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/Product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "slice---src-query-blocks-footer-block-js": () => import("./../../../src/queryBlocks/FooterBlock.js" /* webpackChunkName: "slice---src-query-blocks-footer-block-js" */),
  "slice---src-query-blocks-header-block-js": () => import("./../../../src/queryBlocks/HeaderBlock.js" /* webpackChunkName: "slice---src-query-blocks-header-block-js" */),
  "slice---src-query-blocks-top-footer-block-js": () => import("./../../../src/queryBlocks/TopFooterBlock.js" /* webpackChunkName: "slice---src-query-blocks-top-footer-block-js" */)
}

