import { merge } from "lodash";
import { isBrowser } from "~/utilities/helpers";

export async function fetchInline(url, options) {
    let data = null;

    if (isBrowser) {
        const defaultsOptions = {
            method: "GET",
        };

        const fetchOptions = merge(defaultsOptions, options);

        try {
            const response = await fetch(url, fetchOptions);

            data = await response.json();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    return data;
}
