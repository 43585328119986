import { isBrowser } from "~/utilities/helpers";

const cache = {};

function getLocalStorageItem(storageKey) {
    if (!isBrowser) return;

    return localStorage.getItem(storageKey);
}

function setLocalStorageItem(storageKey, value) {
    if (!isBrowser) return;

    localStorage.setItem(storageKey, value);
}

function removeLocalStorageItem(storageKey) {
    if (!isBrowser) return;

    localStorage.removeItem(storageKey);
}

class LocalStorageItem {
    constructor(key) {
        this.key = key;
    }

    add(value) {
        setLocalStorageItem(this.key, value);
    }

    get() {
        return getLocalStorageItem(this.key);
    }

    addCollection(collection) {
        this.add(JSON.stringify(collection));
    }

    getCollection(ifNotPresent = []) {
        const collection = this.get();
        return collection ? JSON.parse(collection) : ifNotPresent;
    }

    has() {
        return this.get() !== undefined && this.get() !== null;
    }

    delete() {
        removeLocalStorageItem(this.key);
    }
}

function getSingleton(key) {
    if (!cache.hasOwnProperty(key)) {
        cache[key] = new LocalStorageItem(key);
    }

    return cache[key];
}

export { LocalStorageItem, getSingleton };
