export const palette = {
    black: `#2C2C2C`,
    white: `#FFFFFF`,
    darkNavy: `#0A2240`,
    darkBlue: `#005596`,
    mediumBlue: `#307FDE`,
    lightBlue: `#0099DB`,
    yellow: `#FCDE3D`,
    darkGray: `#979797`,
    mediumGray: `#E3E3E3`,
    lightGray: `#F6F6F6`,
    alternateLightGray: `#F0F0F0`,
    tableCellGray: `#FBFBFB`,
    whiteOverlay: `rgb(255, 255, 255, .95)`,
    red: `#FF0000`,
};

export const colors = {
    typography: {
        defaultLightBackground: palette.black,
        defaultDarkBackground: palette.white,
        links: palette.darkBlue,
        linksHover: palette.lightBlue,
    },
};

export const iconLinkColors = {
    white: {
        color: palette.white,
        hover: palette.yellow,
    },
    yellow: {
        color: palette.yellow,
        hover: palette.white,
    },
    blue: {
        color: palette.darkBlue,
        hover: palette.lightBlue,
    },
    lightBlue: {
        color: palette.lightBlue,
        hover: palette.lightBlue,
    },
    black: {
        color: palette.black,
        hover: palette.lightBlue,
    },
};

export const buttonBackgroundColor = {
    yellow: {
        color: palette.yellow,
        hover: palette.lightBlue,
    },
    white: {
        color: palette.white,
        hover: palette.lightGray,
    },
    black: {
        color: palette.black,
        hover: palette.lightBlue,
    },
    blue: {
        color: palette.darkBlue,
        hover: palette.lightBlue,
    },
};

export const spacing = {
    sectionSpacingWidescreen: `100px`,
    sectionSpacingDesktop: `60px`,
    sectionSpacingMobile: `50px`,
    mediumSpacingWidescreen: `70px`,
    mediumSpacingDesktop: `45px`,
    mediumSpacingMobile: `35px`,
    elementSpacingWidescreen: `50px`,
    elementSpacingDesktop: `30px`,
    elementSpacingMobile: `25px`,
    smallSpacingWidescreen: `30px`,
    smallSpacingDesktop: `20px`,
    smallSpacingMobile: `15px`,
};

export const layout = {
    maxContentWidthSmall: `960px`,
    maxContentWidthSmallNumber: 960,
    maxContentWidthMedium: `1152px`,
    maxContentWidthMediumNumber: 1152,
    maxContentWidthLarge: `1344px`,
    maxContentWidthLargeNumber: 1344,
    gutter: `24px`,
    gutterNumber: 24,
    largeGutter: `35px`,
    largeGutterNumber: 35,
    paragraphSpacing: `25px`,
    sectionMargin: `100px`,
    sectionMarginNumber: 100,
};

export const fontFamily = {
    primary: `'Roboto', sans-serif;`,
};

export const breakpointNumbers = {
    mobilePortrait: 375,
    mobilePortraitMax: 374,
    mobileLandscape: 568,
    mobileLandscapeMax: 567,
    tabletPortrait: 768,
    tabletPortraitMax: 767,
    tabletLandscape: 1024,
    tabletLandscapeMax: 1023,
    desktop: 1280,
    desktopMax: 1279,
    largeDesktop: 1414,
    largeDesktopMax: 1413,
};

export const breakpoints = {
    mobilePortrait: `${breakpointNumbers.mobilePortrait}px`,
    mobilePortraitMax: `${breakpointNumbers.mobilePortraitMax}px`,
    mobileLandscape: `${breakpointNumbers.mobileLandscape}px`,
    mobileLandscapeMax: `${breakpointNumbers.mobileLandscapeMax}px`,
    tabletPortrait: `${breakpointNumbers.tabletPortrait}px`,
    tabletPortraitMax: `${breakpointNumbers.tabletPortraitMax}px`,
    tabletLandscape: `${breakpointNumbers.tabletLandscape}px`,
    tabletLandscapeMax: `${breakpointNumbers.tabletLandscapeMax}px`,
    desktop: `${breakpointNumbers.desktop}px`,
    desktopMax: `${breakpointNumbers.desktopMax}px`,
    largeDesktop: `${breakpointNumbers.largeDesktop}px`,
    largeDesktopMax: `${breakpointNumbers.largeDesktopMax}px`,
};

export const zIndex = {
    smallest: 1,
    filters: 2,
    stickyJumpLinks: 12,
    breadcrumbs: 13,
    dropdown: 4,
    mainNav: 5,
    subNav: 6,
    calculatorFooter: 4,
    header: 15,
    cookieNotice: 60,
};

const theme = {
    breakpoints,
    breakpointNumbers,
    buttonBackgroundColor,
    colors,
    fontFamily,
    iconLinkColors,
    layout,
    spacing,
    palette,
    zIndex,
};

export default theme;
