import { useStaticQuery, graphql } from "gatsby";
import React, { useContext, createContext } from "react";

const OptionsContext = createContext();

export const OptionsProvider = ({ children }) => {
    const { wp } = useStaticQuery(graphql`
        query {
            wp {
                optionsPage {
                    insightsOptions {
                        insightsAd {
                            title
                            linkUrl
                            linkLabel
                            copy
                            image {
                                sourceUrl
                                title
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            aspectRatio: 1.44
                                            layout: FULL_WIDTH
                                        )
                                    }
                                }
                            }
                        }
                    }
                    iesConfiguratorLinks {
                        indoor
                        outdoor
                    }
                    searchSectionOptions {
                        searchSection {
                            title
                            links {
                                linkUrl
                                linkLabel
                            }
                        }
                    }
                    productOptions {
                        paybackCalculator {
                            content
                            label
                        }
                        productCategoryBrandOrder
                    }
                    savingsCalculatorOptions {
                        retailLocationLink
                        pdfUrl
                    }
                    cookieNotice {
                        cookieNotice
                    }
                    productSupportEndpoints {
                        creeLightingApiPrefillUrl
                        salesforceApiPrefillUrl
                    }
                    hideTwitter {
                        news
                        events
                        share
                    }
                }
            }
        }
    `);

    const insightsOptions = wp?.optionsPage?.insightsOptions ?? {};
    const searchSection =
        wp?.optionsPage?.searchSectionOptions?.searchSection ?? {};
    const iesConfiguratorLinks = wp?.optionsPage?.iesConfiguratorLinks ?? {};
    const productOptions = wp?.optionsPage.productOptions ?? {};
    const savingsCalculatorOptions =
        wp?.optionsPage?.savingsCalculatorOptions ?? {};
    const cookieNotice = wp?.optionsPage?.cookieNotice?.cookieNotice ?? "";
    const creeLightingApiPrefillUrl =
        wp?.optionsPage?.productSupportEndpoints?.creeLightingApiPrefillUrl ??
        "";
    const salesforceApiPrefillUrl =
        wp?.optionsPage?.productSupportEndpoints?.salesforceApiPrefillUrl ?? "";
    const hideTwitter = wp?.optionsPage?.hideTwitter;

    return (
        <OptionsContext.Provider
            value={{
                insightsOptions,
                searchSection,
                iesConfiguratorLinks,
                productOptions,
                savingsCalculatorOptions,
                cookieNotice,
                creeLightingApiPrefillUrl,
                salesforceApiPrefillUrl,
                hideTwitter,
            }}
        >
            {children}
        </OptionsContext.Provider>
    );
};

export const useOptionsContext = () => {
    const context = useContext(OptionsContext);

    if (context === undefined) {
        throw new Error(
            "useOptionsContext must be used within a OptionsProvider",
        );
    }

    return context;
};
