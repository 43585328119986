import { cloneDeep } from "lodash";

import { isBrowser } from "~/utilities/helpers";
import { upsertOption } from "~/utilities/reducer";

export const creeLightingApiState = {
    lotId: null,
    productInformation: {},
    isLoading: true,
    errorMessage: "",
};

export function creeLightingApiReducer(state, action) {
    let updatedState = state;
    const currentState = cloneDeep(state);

    const { type, lotId, productInformation, errorMessage, error } = action;

    switch (type) {
        case "SET_LOT_ID":
            updatedState = upsertOption(currentState, {
                lotId,
            });
            break;
        case "SET_PRODUCT_DATA":
            updatedState = upsertOption(currentState, {
                productInformation,
                isLoading: false,
                errorMessage: "",
            });
            break;
        case "SET_ERROR":
            updatedState = upsertOption(currentState, {
                isLoading: false,
                errorMessage,
            });

            if (isBrowser && error) {
                console.dir(error);
                console.log("Issues loading product information");
            }

            break;
        default:
            if (isBrowser) {
                console.log(`Unkown Cree Lighting API Action ${type}`);
            }
            break;
    }

    return updatedState;
}
