import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
    createContext,
} from "react";

import useOnWindowResize from "~/hooks/useOnWindowResize";

const StickyContext = createContext();

const initialState = {
    itemsToWatch: [],
    stickyItems: [],
};

const getTopOffset = (element, items) => {
    const offset = items.reduce((acc, curr) => {
        const isAboveElement =
            element.compareDocumentPosition(curr) ===
            Node.DOCUMENT_POSITION_PRECEDING;

        if (isAboveElement) {
            return acc + curr.getBoundingClientRect().height;
        }

        return acc;
    }, 0);

    return offset;
};

const reducer = (state, action) => {
    switch (action.type) {
        case "REGISTER":
            const exists = state.itemsToWatch.find((el) =>
                el.isEqualNode(action.item),
            );
            const itemsOnPage = state.itemsToWatch.filter((el) =>
                document.body.contains(el),
            );

            return {
                ...state,
                itemsToWatch: exists
                    ? itemsOnPage
                    : itemsOnPage.concat(action.item),
            };
        case "UPDATE_STICKY_THINGS":
            return {
                ...state,
                stickyItems: state.itemsToWatch.filter((element) => {
                    if (typeof window !== "undefined") {
                        return getComputedStyle(element).position === "sticky";
                    }

                    return false;
                }),
            };
        default:
            return state;
    }
};

export const StickyProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const registerItemToWatch = useCallback(
        (item) => dispatch({ type: "REGISTER", item }),
        [],
    );

    useEffect(() => {
        dispatch({ type: "UPDATE_STICKY_THINGS" });
    }, [state.itemsToWatch]);

    useOnWindowResize(() => {
        dispatch({ type: "UPDATE_STICKY_THINGS" });
    }, false);

    return (
        <StickyContext.Provider
            value={{
                stickyItems: state.stickyItems,
                registerItemToWatch,
                getTopOffset,
            }}
        >
            {children}
        </StickyContext.Provider>
    );
};

const getStickyItemsHeight = (stickyItems) => {
    return stickyItems.reduce((totalHeight, item) => {
        return totalHeight + item.getBoundingClientRect().height;
    }, 0);
};

export const useStickyContext = () => {
    const context = useContext(StickyContext);
    const ref = useRef();
    const [elementOffset, setElementOffset] = useState(0);

    if (context === undefined) {
        throw new Error(
            "useStickyContext must be used within a OptionsProvider",
        );
    }

    const { stickyItems, registerItemToWatch } = context;
    const totalHeight = useMemo(
        () => getStickyItemsHeight(stickyItems),
        [stickyItems],
    );

    useEffect(() => {
        if (ref.current) {
            registerItemToWatch(ref.current);
        }
    }, [registerItemToWatch]);

    useEffect(() => {
        if (ref.current) {
            const offsetTop = getTopOffset(ref.current, stickyItems);

            setElementOffset(offsetTop);
        }
    }, [stickyItems]);

    return { elementOffset, ref, totalHeight, stickyItems };
};
