import { filter, find, reduce, concat, map, includes, has } from "lodash";

export const getProductImages = (images) => {
    if (!Array.isArray(images)) return [];

    return reduce(
        images,
        (acc, curr) => {
            if (curr.exclude) return acc;

            return concat(acc, {
                type: "image",
                ...curr,
            });
        },
        [],
    );
};

export const getProductVideos = (videos) => {
    if (!Array.isArray(videos)) return [];

    return reduce(
        videos,
        (acc, curr) => {
            if (curr.exclude) return acc;

            return concat(acc, {
                type: "video",
                ...curr,
            });
        },
        [],
    );
};

const filterProductAttrsByAttrToShow = (attrs, attributesToShow) => {
    const filterByValue = filter(attrs, ({ value }) =>
        includes(attributesToShow, value),
    );

    return filterByValue.sort((a, b) => {
        return (
            attributesToShow.indexOf(a.value) -
            attributesToShow.indexOf(b.value)
        );
    });
};

export const formatProductsForTable = (products, attributesToShow) =>
    map(products, ({ productAttributes, ...rest }) => ({
        ...rest,
        attributes: filterProductAttrsByAttrToShow(
            productAttributes,
            attributesToShow,
        ),
    }));

export const formatProductsForTableUsingFilters = (
    products,
    attributesToShow,
) =>
    map(products, ({ productFilters, ...rest }) => ({
        ...rest,
        attributes: filterProductAttrsByAttrToShow(
            productFilters,
            attributesToShow,
        ),
    }));

export const formatProductsTableRows = (products) => {
    if (!Array.isArray(products)) return [];

    return reduce(
        products,
        (acc, product) => {
            const attributes = map(product.attributes, (attr) => ({
                id: attr.value,
                label: attr.label,
                type: attr.type,
                image: attr.image,
                content: attr.options,
            }));

            return concat(acc, {
                id: product.slug,
                data: [
                    {
                        id: "family",
                        label: "Family",
                        content: product.title,
                    },
                    ...attributes,
                ],
            });
        },
        [],
    );
};

export const getProductUrl = (product, currentCategory) => {
    const categories = product.productCategories?.nodes;

    if (!categories) return { url: `/${product.slug}` };

    let url = `/products/`;
    let topLevelSlug = "";
    let secondarySlug = "";

    const topLevelCategory = find(
        categories,
        (category) => !category.ancestors,
    );
    topLevelSlug = topLevelCategory ? topLevelCategory.slug : "";
    const notTopLevelCategoryies = filter(
        categories,
        (category) => category.ancestors && category.ancestors.nodes,
    );
    let secondaryCategory;

    if (notTopLevelCategoryies.length === 1) {
        secondaryCategory = notTopLevelCategoryies[0];
    } else if (notTopLevelCategoryies.length > 1 && currentCategory?.slug) {
        secondaryCategory = find(notTopLevelCategoryies, (category) => {
            const topLevelSlug = category.ancestors.nodes[0].slug;
            return topLevelSlug === currentCategory.slug;
        });
    } else {
        secondaryCategory = null;
    }

    secondarySlug = secondaryCategory ? secondaryCategory.slug : "";

    if (currentCategory) {
        let currentCategoryTop = currentCategory;
        let currentCategorySecondary = null;

        if (has(currentCategory, "ancestors") && currentCategory.ancestors) {
            currentCategoryTop = find(
                currentCategory.ancestors.nodes,
                (category) => !category.ancestors,
            );
            currentCategorySecondary = currentCategory;
        }

        topLevelSlug = currentCategoryTop.slug;
        secondarySlug = currentCategorySecondary
            ? currentCategorySecondary.slug
            : "";
    }

    return topLevelSlug && secondarySlug
        ? `${url}${topLevelSlug}/${secondarySlug}/${product.slug}`
        : `/${product.slug}`;
};

export const getTopLevelCategory = (productCategories) => {
    const categories = productCategories?.nodes;

    if (!categories) return null;

    return find(categories, (category) => !category.ancestors);
};

export const getSecondaryCategory = (
    productCategories,
    primaryCategorySlug,
    currentCategory,
) => {
    const categories = productCategories?.nodes;

    if (!categories) return null;

    const notTopLevelCategoryies = filter(
        categories,
        (category) => category.ancestors && category.ancestors.nodes,
    );

    if (
        (currentCategory?.slug === "outdoor" ||
            currentCategory?.slug === "indoor") &&
        notTopLevelCategoryies.length > 1
    ) {
        return find(notTopLevelCategoryies, (category) => {
            const topLevelSlug = category.ancestors.nodes[0].slug;
            return topLevelSlug === currentCategory.slug;
        });
    }

    if (notTopLevelCategoryies.length) {
        if (primaryCategorySlug) {
            const primary = find(
                notTopLevelCategoryies,
                ({ slug }) => slug === primaryCategorySlug?.slug,
            );
            if (primary) {
                return primary;
            } else {
                return notTopLevelCategoryies[0];
            }
        } else {
            return notTopLevelCategoryies[0];
        }
    } else {
        return null;
    }
};

export function getAssociatedDocuments(productInformation, tabSlug) {
    let associatedDocuments = null;

    if (productInformation?.productToDocument) {
        associatedDocuments = filter(
            productInformation.productToDocument,
            ({ tabLocations }) => find(tabLocations.nodes, { slug: tabSlug }),
        );
    }

    return associatedDocuments;
}

const getRangeOutput = (productAttributes, rangeValue) => {
    let output = "";
    const range = find(productAttributes, ({ value }) => value === rangeValue);

    if (range && range.options?.length) {
        output = range.options[0].label;
    }

    return output;
};

export const getLumenOutput = (productAttributes) =>
    getRangeOutput(productAttributes, "lumen_range");
export const getWattageRange = (productAttributes) =>
    getRangeOutput(productAttributes, "wattage_range");

export const getAttributeOrder = (productAttributes) => {
    let order = [];
    const attrOrders = find(
        productAttributes,
        ({ value }) => value === "attribute_order",
    );

    if (attrOrders && attrOrders.options) {
        order = map(attrOrders.options, ({ value }) => value);
    }

    return order;
};

export const getAdditionalCardAttributes = (productAttributes) => {
    let attributes = [];

    const addtionalAttributes = find(
        productAttributes,
        ({ value }) => value === "additional_product_card_attributes",
    );

    if (addtionalAttributes) {
        attributes = addtionalAttributes.options;
    }

    return attributes;
};
