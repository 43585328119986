import React, { useContext, createContext, useReducer, useEffect } from "react";
import { parseISO } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";

import { fetchInline } from "~/utilities/fetchHelper";

import {
    creeLightingApiState,
    creeLightingApiReducer,
} from "~/reducers/creeLightingApi";

import { useOptionsContext } from "~/context/OptionsContext";

const CreeLightingApiContext = createContext();

const formatWithTZ = (date, fmt, tz) =>
    format(toZonedTime(date, tz), fmt, { timeZone: tz });

async function fetchCreeLightingApiData(
    creeLightingApiPrefillUrl,
    lotId,
    dispatch,
    signal,
) {
    try {
        const response = await fetchInline(
            `${process.env.GATSBY_ADMIN_SITE_URL}${creeLightingApiPrefillUrl}${lotId}`,
            { signal },
        );

        if (response?.status === 200 && response?.data) {
            const formattedData = {
                ...response.data,
                workOrderDate: response.data.workOrderDate
                    ? formatWithTZ(
                          parseISO(response.data.workOrderDate),
                          "MM/dd/yyyy",
                          "UTC",
                      )
                    : "",
                warrantyDate: response.data.warrantyDate
                    ? formatWithTZ(
                          parseISO(response.data.warrantyDate),
                          "MM/dd/yyyy",
                          "UTC",
                      )
                    : "",
            };

            dispatch({
                type: "SET_PRODUCT_DATA",
                productInformation: formattedData,
            });
        } else {
            dispatch({ type: "SET_ERROR", errorMessage: response.message });
        }
    } catch (e) {
        if (e instanceof DOMException && e.name === "AbortError") {
            return;
        } else {
            dispatch({
                type: "SET_ERROR",
                errorMessage: "Issues loading product information",
                error: e,
            });
        }
    }
}

export const CreeLightingApiProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        creeLightingApiReducer,
        creeLightingApiState,
    );
    const { lotId, productInformation, isLoading, errorMessage } = state;

    const { creeLightingApiPrefillUrl } = useOptionsContext();

    useEffect(() => {
        const abortController = new AbortController();

        if (creeLightingApiPrefillUrl && lotId) {
            // attempt to get product data
            fetchCreeLightingApiData(
                creeLightingApiPrefillUrl,
                lotId,
                dispatch,
                abortController.signal,
            );
        }

        return () => abortController.abort();
    }, [creeLightingApiPrefillUrl, lotId]);

    const setLotId = (newLotId) => {
        if (newLotId !== lotId) {
            dispatch({ type: "SET_LOT_ID", lotId: newLotId });
        }
    };

    return (
        <CreeLightingApiContext.Provider
            value={{
                lotId,
                productInformation,
                setLotId,
                isLoading,
                errorMessage,
            }}
        >
            {children}
        </CreeLightingApiContext.Provider>
    );
};

export const useCreeLightingApiContext = () => {
    const context = useContext(CreeLightingApiContext);

    if (context === undefined) {
        throw new Error(
            "useCreeLightingApiContext must be used within a CreeLightingApiProvider",
        );
    }

    return context;
};
