import { useEffect } from "react";

const useOnWindowResize = (resizeListener, fireOnPageLoad = true) => {
    useEffect(() => {
        if (fireOnPageLoad) {
            resizeListener();
        }

        window.addEventListener("resize", resizeListener);

        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, [resizeListener, fireOnPageLoad]);
};

export default useOnWindowResize;
