import React from "react";
import { cloneDeep, isBoolean, isEmpty, mergeWith, reduce } from "lodash";
import parser, { attributesToProps, domToReact } from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { Script } from "gatsby";

import Row from "~/components/layout/Row";
import Col from "~/components/layout/Col";
import striptags from "striptags";

export const KEYCODE_TAB = "Tab";
export const KEYCODE_ESCAPE = "Escape";
export const KEYCODE_SPACE = "Space";

export const mergeDeep = (startObj, ...objects) => {
    const start = cloneDeep(startObj);
    const rest = cloneDeep(objects);

    const customizer = (destination, source) => {
        if (!isBoolean(source) && isEmpty(source)) {
            return destination;
        }
    };

    return reduce(
        rest,
        (destination, source) => {
            return mergeWith(destination, source, customizer);
        },
        start,
    );
};

export const createShareLinks = (url, title) => {
    return [
        {
            icon: `facebook-f`,
            linkUrl: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
            screenReaderText: `Share on Facebook`,
        },
        {
            icon: `twitter`,
            linkUrl: `https://twitter.com/intent/tweet?url=${url}&text=`,
            screenReaderText: `Share on Twitter`,
        },
        {
            icon: `linkedin-in`,
            linkUrl: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=&source=`,
            screenReaderText: `Share on LinkedIn`,
        },
        {
            icon: `envelope`,
            iconLibrary: `fas`,
            linkUrl: `mailto:?&subject=&body=${url}`,
            screenReaderText: `Share in an email`,
        },
    ];
};

export const getCorrectCtas = (postOverride, productLinesOverride, global) => {
    return postOverride?.length
        ? postOverride
        : productLinesOverride?.length
          ? productLinesOverride
          : global?.length
            ? global
            : [];
};

export const sanitizeAndParseHTML = (content, parserOptions) => {
    if (!content) return null;

    const finalOptions = {
        replace: (domNode) => {
            if (domNode?.name === "script") {
                return (
                    <Script {...domNode.attribs}>
                        {domNode.children[0]?.data}
                    </Script>
                );
            }
            if (domNode?.name === "column") {
                const props = attributesToProps(domNode.attribs);
                return (
                    <Col {...props}>
                        {domToReact(domNode.children, finalOptions)}
                    </Col>
                );
            }
            if (domNode?.name === "row") {
                const props = attributesToProps(domNode.attribs);
                return (
                    <Row {...props}>
                        {domToReact(domNode.children, finalOptions)}
                    </Row>
                );
            }
        },
        ...parserOptions,
    };

    return parser(
        sanitizeHtml(content, {
            allowedTags: false,
            allowedAttributes: false,
            allowVulnerableTags: true,
        }),
        finalOptions,
    );
};

export const isBrowser = typeof window !== "undefined";

export function getElementFromHash(hash) {
    const trimmedHash = hash.replaceAll("/", "");
    let element = null;

    if (isBrowser) {
        try {
            element = document.querySelector(trimmedHash);
        } catch (error) {
            console.log(error);
        }
    }

    return element;
}

export function scrollToElement(element, offset = 0) {
    if (!element) return;

    const y = element.getBoundingClientRect().top + window.pageYOffset - offset;

    window.scrollTo({
        top: y,
        behavior: "smooth",
    });
}

export const createIdFromString = (str) => str.replace(/\W/g, "_");

/**
 * Remove linebreaks, trialing whitespace, and HTML tags
 */
export const clean = (str) => {
    return striptags(str.trim().replace(/(\r\n|\n|\r)/gm, ""));
};

const removeFirstAndLastForwardSlash = (str) => {
    if (!str) return;

    return str.replace(/^\/|\/$/g, "");
};

export const createURL = (url) => {
    let parsedURL = null;

    try {
        parsedURL = new URL(url);
    } catch (e) {
        let fixedURL = url;

        if (url !== "/") {
            fixedURL = `/${removeFirstAndLastForwardSlash(url)}`;
        }

        return new URL(fixedURL, process.env.GATSBY_SITE_URL);
    }

    if (parsedURL.hostname === "") {
        parsedURL = new URL(url, process.env.GATSBY_SITE_URL);
    }
    return parsedURL;
};
