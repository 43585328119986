import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/900.css";

let nextRoute = ``;

export const onPreRouteUpdate = ({ location }) => {
    nextRoute = location.pathname;
};

if (typeof window !== `undefined`) {
    window.addEventListener("unhandledrejection", function (event) {
        if (/loading chunk \d* failed./i.test(event.reason)) {
            if (nextRoute) {
                window.location.pathname = nextRoute;
            }
        }
    });

    if (!window.crypto) {
        window.crypto = window.msCrypto;
    }
}

export const shouldUpdateScroll = ({ routerProps }) => {
    const { disableScrollUpdate } = routerProps.location.state ?? {};

    return !disableScrollUpdate;
};

export { wrapRootElement } from "./gatsby-ssr";
export { wrapPageElement } from "./gatsby-ssr";
