import { ThemeProvider } from "@emotion/react";
import React from "react";

import theme from "./src/theme";

import { OptionsProvider } from "~/context/OptionsContext";
import { StickyProvider } from "~/context/StickyContext";
import { CompareProvider } from "~/context/CompareContext";
import { SearchProvider } from "~/context/SearchContext";
import { IntersectionProvider } from "~/context/IntersectionContext";
import { LocationProvider } from "~/context/LocationContext";
import { CreeLightingApiProvider } from "~/context/CreeLightingApiContext";
import { SalesforceApiProvider } from "~/context/SalesforceApiContext";
import { GravityFormsProvider } from "~/context/GravityFormsContext";

export const onRenderBody = ({ setHtmlAttributes }) => {
    setHtmlAttributes({ lang: "en-us" });
};

const GlobalContext = ({ element, props = false }) => {
    return (
        <LocationProvider pageProps={props}>
            <CompareProvider>
                <OptionsProvider>
                    <SearchProvider>
                        <CreeLightingApiProvider>
                            <SalesforceApiProvider>
                                <GravityFormsProvider>
                                    <IntersectionProvider>
                                        <StickyProvider>
                                            <ThemeProvider {...{ theme }}>
                                                {element}
                                            </ThemeProvider>
                                        </StickyProvider>
                                    </IntersectionProvider>
                                </GravityFormsProvider>
                            </SalesforceApiProvider>
                        </CreeLightingApiProvider>
                    </SearchProvider>
                </OptionsProvider>
            </CompareProvider>
        </LocationProvider>
    );
};

export const wrapRootElement = ({ element }) => {
    return <GlobalContext element={element} />;
};

export const wrapPageElement = ({ element, props }) => {
    return <GlobalContext element={element} props={props} />;
};
