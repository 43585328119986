import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { fadeIn } from "~/styles/animations";

const handleBreakpoints = ({ theme, xs, sm, md, lg, xl }) => {
    let styles = ``;

    if (xs) {
        styles += `
            @media (min-width: ${theme.breakpoints.mobilePortrait}) {
                width: ${typeof xs === "string" ? xs : `${xs * 100}%`};
            }
        `;
    }

    if (sm) {
        styles += `
            @media (min-width: ${theme.breakpoints.tabletPortrait}) {
                width: ${typeof sm === "string" ? sm : `${sm * 100}%`};
            }
        `;
    }

    if (md) {
        styles += `
            @media (min-width: ${theme.breakpoints.tabletLandscape}) {
                width: ${typeof md === "string" ? md : `${md * 100}%`};
            }
        `;
    }

    if (lg) {
        styles += `
            @media (min-width: ${theme.breakpoints.desktop}) {
                width: ${typeof lg === "string" ? lg : `${lg * 100}%`};
            }
        `;
    }

    if (xl) {
        styles += `
            @media (min-width: ${theme.breakpoints.largeDesktop}) {
                width: ${typeof xl === "string" ? xl : `${xl * 100}%`};
            }
        `;
    }

    return css`
        ${styles}
    `;
};

const getPadding = ({ noGutter, noVerticalSpacing, theme }) => {
    return css`
        padding-left: ${noGutter ? `0` : `${theme.layout.gutterNumber / 2}px`};
        padding-right: ${noGutter ? `0` : `${theme.layout.gutterNumber / 2}px`};
        padding-top: ${noVerticalSpacing ? `0` : theme.layout.gutter};
        padding-bottom: 0;

        @media (min-width: ${theme.breakpoints.largeDesktop}) {
            padding-left: ${noGutter
                ? `0`
                : `${theme.layout.largeGutterNumber / 2}px`};
            padding-right: ${noGutter
                ? `0`
                : `${theme.layout.largeGutterNumber / 2}px`};
            padding-top: ${noVerticalSpacing ? `0` : theme.layout.largeGutter};
        }
    `;
};

const Col = styled.div`
    ${getPadding};
    display: ${({ flex }) => (flex ? `flex` : `block`)};
    flex-direction: ${({ flexColumn }) => (flexColumn ? "column" : "row")};
    flex: 0 1 auto;
    width: 100%;
    animation: ${({ animated }) =>
        animated
            ? css`
                  ${fadeIn} 0.5s ease
              `
            : `none`};

    ${handleBreakpoints};
`;

export default Col;
