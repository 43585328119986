import { find, has, isEmpty, reduce, snakeCase } from "lodash";
import queryString from "query-string";
import { navigate } from "gatsby";

import { isBrowser } from "~/utilities/helpers";

export const ENGLISH_CODE = "en";
export const CODE_NAME = "lang";

export const formatParams = (code, search) => {
    const queryParams = queryString.parse(search);

    const copy = {
        ...queryParams,
        [CODE_NAME]: code,
    };

    return queryString.stringify(copy);
};

export const navigateToPreferredLanguage = () => {
    if (!isBrowser || isEmpty(navigator.language)) {
        return;
    }

    const search = window.location.search;
    const preferred = snakeCase(navigator.language);
    const queryParams = queryString.parse(search);

    if (snakeCase("en-US") !== preferred && !has(queryParams, CODE_NAME)) {
        navigate(`?${formatParams(preferred, search)}`);
    }
};

export const getTemplateStrings = (stringKeys, templateStrings, activeCode) => {
    return reduce(
        stringKeys,
        (acc, name) => {
            const templateString = find(templateStrings, { name });
            if (templateString) {
                const label = find(templateString.translations, {
                    languageCode: activeCode,
                })?.translatedString;
                if (label) {
                    return { ...acc, [name]: label };
                }
            }
            return acc;
        },
        {},
    );
};
