import React, {
    useEffect,
    useReducer,
    useContext,
    createContext,
    useCallback,
} from "react";
import fetch from "isomorphic-fetch";
import { unionBy } from "lodash";

const ACTIONS = {
    RESPONSE_FAILURE: "RESPONSE_FAILURE",
    RESPONSE_SUCCESS: "RESPONSE_SUCCESS",
    REQUEST_START: "REQUEST_START",
    SET_PAGE_NUMBER: 1,
    SET_PER_PAGE: "SET_PER_PAGE",
    SET_KEYWORD: "SET_KEYWORD",
};

const theReducer = (state, { type, ...payload }) => {
    switch (type) {
        case ACTIONS.REQUEST_START:
            return {
                ...state,
                hasNextPage: true,
                loading: true,
            };

        case ACTIONS.RESPONSE_SUCCESS:
            let newData = [...payload.data];

            if (state.pageNumber > 1) {
                newData = unionBy(state.data, newData, "id");
            }

            return {
                ...state,
                ...payload,
                data: newData,
                loading: false,
            };
        case ACTIONS.RESPONSE_FAILURE:
            return {
                ...state,
                ...payload,
                hasNextPage: false,
                loading: false,
            };
        case ACTIONS.SET_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: state.pageNumber + 1,
            };
        case ACTIONS.SET_PER_PAGE:
            return {
                ...state,
                perPage: payload.newPerPage,
            };
        case ACTIONS.SET_KEYWORD:
            return {
                ...state,
                keyword: payload.newKeyword,
                pageNumber: 1,
            };
        default:
            return state;
    }
};

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const initialState = {
        data: [],
        error: null,
        hasNextPage: false,
        loading: false,
        totalCount: 0,
        pageNumber: 1,
        keyword: "",
        perPage: 25,
    };

    const [state, dispatch] = useReducer(theReducer, initialState);

    useEffect(() => {
        const handleSearch = async () => {
            dispatch({
                type: ACTIONS.REQUEST_START,
            });

            const url = `${process.env.GATSBY_ADMIN_SITE_URL}/wp-json/relevanssi/v1/search`;

            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    keyword: state.keyword,
                    posts_per_page: state.perPage,
                    paged: state.pageNumber,
                }),
                headers: { "Content-Type": "application/json" },
            });

            if (response.ok) {
                const body = await response.json();

                const hasNextPage =
                    state.pageNumber <
                    Number.parseInt(response.headers.get("X-WP-TotalPages"));

                const totalCount = Number.parseInt(
                    response.headers.get("X-WP-Total"),
                );

                dispatch({
                    type: ACTIONS.RESPONSE_SUCCESS,
                    data: body.data,
                    hasNextPage,
                    totalCount,
                });
            } else {
                const error = new Error("Unable to fetch results");

                error.response = response;

                throw error;
            }
        };

        if (state.keyword) {
            handleSearch().catch((error) => {
                console.error("useDynamicSearch", "error", error);

                dispatch({
                    type: ACTIONS.RESPONSE_FAILURE,
                    error,
                });
            });
        }
    }, [state.pageNumber, state.keyword, state.perPage]);

    const setKeyword = useCallback((newKeyword) => {
        dispatch({ type: ACTIONS.SET_KEYWORD, newKeyword });
    }, []);

    const setPerPage = useCallback((newPerPage) => {
        dispatch({ type: ACTIONS.SET_PER_PAGE, newPerPage });
    }, []);

    const loadMore = useCallback(() => {
        dispatch({ type: ACTIONS.SET_PAGE_NUMBER });
    }, []);

    return (
        <SearchContext.Provider
            value={{
                ...state,
                loadMore,
                setKeyword,
                setPerPage,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export const useSearchContext = () => {
    const context = useContext(SearchContext);

    if (context === undefined) {
        throw new Error(
            "useSearchContext must be used within a SearchProvider",
        );
    }

    return context;
};
